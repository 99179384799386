
#pageBackground {
    background-color: #E7E7E7;
    text-align: left;
}

#customButton {
        background-color: var(--golden-yellow);
        border: 2px solid #555;
        color: #555;
        transition: all 0.3s ease;
}

#customButton:hover {
    background-color: #E0B200;
    color: #111;
    border-color: #111;
}

.link-hover {
    position: relative;
    text-decoration: none;
    color: #555; /* Adjust as needed */
    transition: color 0.4s ease-in-out;
    display: inline-block;
  }
  
  .link-hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust spacing from text */
    left: 0;
    width: 10%;
    height: 3px; /* Thickness of underline */
    background-color: #FFC800; /* Golden Yellow */
    transition: width 0.3s ease-in-out;
  }
  
  .link-hover:hover {
    color: #000; /* text color while hovering */
  }
  
  .link-hover:hover::after {
    width: 100%; /* Expands the underline */
  }

  li {
    font-family: "Nunito";
    font-weight: "300";
    padding-left: "10%";
    font-size: "1rem";
  }

  .svgColumn {
    margin-left: -5%;
  }
  


// h1, h2, h3 {
//     font-family: 'Carter One', cursive;
//   }

// h1, h2, h3, h4 {
//     font-family: 'Montserrat' !important;
// }

// .different {
//     font-family: 'Quicksand' !important;
// }

// p {
//     font-family: 'Quicksand' !important;
// }


//  p, button, h4 {
//     font-family: 'Nunito', sans-serif;
//     font-weight: 300;
//   }
  