@use '../../App.scss' as *;

#quizContent {
    background-color: $purpleBase;
    color: $textWhite;  
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border-radius: 3%;
}

.answerText { 
    padding: 1%;
    &:hover {
        background-color: darkviolet;
        // border: 2px, solid, $textWhite;
        outline: 2px solid $textWhite;
        outline-offset: -2px
    }
}

