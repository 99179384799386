.google-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 50px;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    font-family: Arial, sans-serif;
}

.google-btn:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.google-icon-wrapper {
    width: 40px;
    height: 100%;
    border-right: 1px solid #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-icon {
    width: 18px;
    height: 18px;
}

.btn-text {
    flex-grow: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin: 0;
}

.btn-text b {
    color: #555;
}
