.matching {
    background-color: darkslategray;
}

// .finished {
//     border-style: solid;
//     border-color: lightgreen;
//     border-width: 5px;
//     background-color: white;  
// }

.box {
    height: 12vh;
}

.clicked {
    border-color: darkslategray;
    border-width: 3px;
    // height: 16vh;
    // background-color: lightgreen;
    // color: lightgreen;
}

// .clicked {
//     transform: scale(1.1);
//     opacity: 0.8;
//     // border-color: yellow;
//     // border-width: 3px;
//     // background-color: yellow;
//     // color: black;
// }

// .clicked:hover {
//     border-color: lightgreen;
//     border-width: 3px;
//     // height: 16vh;
//     // background-color: lightgreen;
//     color:lightgreen;
// }

.matched {
    border-color: green;
    border-width: 3px;
    background-color: lightgreen;
    // color:black;
    color:black;
}

.matched:hover {
    border-color: green;
    border-width: 3px;
    background-color: lightgreen;
    color: black;
}

.incorrect {
    border-color:red;
    border-width: 3px;
    background-color: red;
    // color:black;
    color: black;
}

.incorrect:hover {
    border-color: black;
    border-width: 3px;
    background-color: red;
    color: black;
}